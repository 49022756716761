// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("packs/mrslider.jquery")
require("packs/jquery.imageview")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")

var Trix = require("trix");

/* what the newly created button does */
Trix.config.textAttributes.red = {
    style: { color: "red" },
    parser: function(element) {
        alert('start');
        return element.style.color === "red"
    },
    inheritable: true
}

/* insert the button visual in the default toolbar */
addEventListener("trix-initialize", function(event) {
    var buttonHTML = '<button type="button" data-trix-attribute="red">RED</button>'

    event.target.toolbarElement.
    querySelector(".trix-button-group").
    insertAdjacentHTML("beforeend", buttonHTML)
})

$(document).ready(function() {

    // Клик по ссылке "Закрыть".
    $('.open-modal.neok').on('click', function() {
        $('.modal-overlay.neok').fadeIn();
        return false;
    });    

    $('.modal-close').on('click', function() {
        $('.modal-overlay').fadeOut();
        return false;
    });  

    $('.open-modal.ok').on('click', function() {
        $('.modal-overlay.ok').fadeIn();
        return false;
    });    


    $('.send-message').on("ajax:beforeSend", function() {
        $('.send-message').find('.buttonA').attr('disabled', true).val('Отправка...').addClass("sended");
    }).on("ajax:success", function(data) {
        $('.send-message').find('.buttonA').attr('disabled', true).val('Успешно отправлено!').addClass("sended"); 
    }).on("ajax:error", function(xhr, str) {
        $('.send-message').find('.buttonA').attr('disabled', true).val('Ошибка отправки').addClass("error"); 
    })  


    function flickr_pagination() {
      if ( $( window ).width() < 500 ) {
        $('.flickr_pagination .previous_page').text('←');
        $('.flickr_pagination .next_page').text('→');
      } else {
        $('.flickr_pagination .previous_page').text('← Предыдущая');
        $('.flickr_pagination .next_page').text('Следующая →');
      }
    }
    $( window ).resize(function() {
        flickr_pagination()
    });
    flickr_pagination();

    $('.toggle_groups').on('click', function() {
        $('.toggle_groups').hide();
        $('.groups.hide').show()
    });
 
});  



  

